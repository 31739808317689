<template>
  <div>
    <b-row align-h="between">
      <b-col>
        <b-button
          variant="primary"
          @click="$router.push({ name: 'administrativo.fornecedor' })"
        >
          <feather-icon
            icon="ArrowLeftIcon"
          />
          Voltar
        </b-button>
      </b-col>
      <b-col cols="auto">
        <b-button
          variant="primary"
          @click="$refs.modalContrato.show(), createUpdateContrato=true, clearContrato(), edicao=false"
        >
          <feather-icon
            icon="PlusIcon"
          />
          Contrato
        </b-button>
      </b-col>
    </b-row>
    <b-card class="mt-2">
      <b-row>
        <b-col>
          <b-row>
            <b-col md="12">
              <b-row class="d-flex justify-content-between">
                <b-col md="3">
                  <h5><strong class="text-primary">Razão Social: </strong></h5>
                </b-col>
                <b-col>
                  <h5>
                    {{ infoFornecedor.razao_social }}
                  </h5>
                </b-col>
              </b-row>

              <b-row class="d-flex justify-content-between">
                <b-col md="3">
                  <h5><strong class="text-primary">Fantasia: </strong></h5>
                </b-col>
                <b-col>
                  <h5>
                    {{ infoFornecedor.nome_fantasia }}
                  </h5>
                </b-col>
              </b-row>

              <b-row class="d-flex justify-content-between">
                <b-col md="3">
                  <h5><strong class="text-primary">Razão Social: </strong></h5>
                </b-col>
                <b-col>
                  <h5>
                    {{ infoFornecedor.telefone }}
                  </h5>
                </b-col>
              </b-row>

              <b-row class="d-flex justify-content-between">
                <b-col md="3">
                  <h5><strong class="text-primary">Email: </strong></h5>
                </b-col>
                <b-col>
                  <h5>
                    {{ infoFornecedor.email }}
                  </h5>
                </b-col>
              </b-row>

              <b-row class="d-flex justify-content-between">
                <b-col md="3">
                  <h5><strong class="text-primary">Site: </strong></h5>
                </b-col>
                <b-col>
                  <h5>
                    {{ infoFornecedor.site }}
                  </h5>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-col>
        <b-col>
          <b-row>
            <b-col md="12">
              <b-row class="d-flex justify-content-between">
                <b-col md="3">
                  <h5><strong class="text-primary">Representante: </strong></h5>
                </b-col>
                <b-col>
                  <h5>
                    {{ infoFornecedor.representante }}
                  </h5>
                </b-col>
              </b-row>

              <b-row class="d-flex justify-content-between">
                <b-col md="3">
                  <h5><strong class="text-primary">Contato: </strong></h5>
                </b-col>
                <b-col>
                  <h5>
                    {{ infoFornecedor.contato_representante }}
                  </h5>
                </b-col>
              </b-row>

              <b-row class="d-flex justify-content-between">
                <b-col md="3">
                  <h5><strong class="text-primary">Observação: </strong></h5>
                </b-col>
                <b-col>
                  <h5>
                    {{ infoFornecedor.observacao }}
                  </h5>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-card>
    <b-modal
      ref="modalContrato"
      hide-footer
    >
      <b-row>
        <b-col
          cols="12"
          class="mt-1"
        >
          Resposável :
          <b-form-input
            v-model="contrato.responsavel"
          />
        </b-col>
        <b-col
          cols="12"
          class="mt-1"
        >
          Telefone :
          <b-form-input
            v-model="contrato.telefone_responsavel"
          />
        </b-col>
        <b-col
          cols="12"
          class="mt-1"
        >
          Email :
          <b-form-input
            v-model="contrato.email_responsavel"
          />
        </b-col>
        <b-col
          cols="12"
          class="mt-1"
        >
          Descrição :
          <b-form-input
            v-model="contrato.descricao"
          />
        </b-col>
        <b-col
          cols="6"
          class="mt-1"
        >
          Inicio :
          <b-form-input
            v-model="contrato.data_inicio"
            type="date"
          />
        </b-col>

        <b-col
          cols="6"
          class="mt-1"
        >
          Final :
          <b-form-input
            v-model="contrato.data_final"
            type="date"
          />
        </b-col>
        <b-col
          cols="12"
          class="mt-1"
        >
          Objeto :
          <b-form-textarea
            v-model="contrato.objeto"
          />
        </b-col>
      </b-row>
      <hr>
      <b-row
        v-if="createUpdateContrato"
        align-h="end"
        class="mt-1"
      >
        <b-col cols="auto">
          <b-button
            variant="danger"
            @click="$refs.modalContrato.hide()"
          >
            Cancelar
          </b-button>
        </b-col>
        <b-col cols="auto">
          <b-button
            variant="primary"
            @click="storeContrato"
          >
            Cadastrar
          </b-button>
        </b-col>
      </b-row>
      <b-row
        v-if="!createUpdateContrato"
        align-h="end"
        class="mt-1"
      >
        <!-- <b-col>
          <b-button
            variant="outline-secondary"
            @click="edicao=false"
          >
            Editar
          </b-button>
        </b-col>
        <b-col>
          <b-button
            variant="outline-danger"
            @click="deleteContrato"
          >
            Excluir
          </b-button>
        </b-col> -->
        <b-col cols="auto">
          <b-button
            variant="danger"
            @click="$refs.modalContrato.hide()"
          >
            Cancelar
          </b-button>
        </b-col>
        <b-col cols="auto">
          <b-button
            variant="primary"
            @click="updateContrato"
          >
            Salvar
          </b-button>
        </b-col>
      </b-row>
    </b-modal>
    <b-card class="mt-2">
      <b-row>
        <b-col cols="12">
          <b-row>
            <b-col cols="12">
              <b-row
                align-h="center"
                class="mb-2"
              >
                <b-col cols="8">
                  <b-form-input
                    v-model="tableContratos.filter"
                    placeholder="Pesquisar"
                    type="search"
                    size="sm"
                  />
                </b-col>
              </b-row>
            </b-col>
            <b-col cols="12">
              <b-table
                responsive
                bordered
                striped
                :per-page="tableContratos.perPage"
                :current-page="tableContratos.currentPage"
                :fields="tableContratos.fields"
                :items="tableContratos.items"
              >
                <template #cell(opcoes)="data">
                  <b-row align-h="strech">
                    <b-col cols="auto">
                      <feather-icon
                        icon="EditIcon"
                        class="color-icon"
                        @click="rowClicked(data.item)"
                      />
                    </b-col>
                    <b-col cols="auto">
                      <feather-icon
                        icon="Trash2Icon"
                        class="color-icon"
                        @click="deleteContrato"
                      />
                    </b-col>
                  </b-row>
                </template>
              </b-table>
            </b-col>
            <b-col cols="12">
              <b-pagination
                v-model="tableContratos.currentPage"
                :total-rows="tableContratos.totalRows"
                :per-page="tableContratos.perPage"
                align="center"
                size="sm"
              />
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>
<script>
import {
  BRow,
  BCol,
  BButton,
  BCard,
  BTable,
  BFormInput,
  BFormTextarea,
  BModal,
  BPagination,
} from 'bootstrap-vue'
import axios from '@/../axios-auth'
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue'

export default {
  components: {
    BRow,
    BCol,
    BButton,
    BCard,
    BTable,
    BFormInput,
    BFormTextarea,
    BModal,
    BPagination,
    FeatherIcon,
  },
  data() {
    return {
      idContrato: null,
      infoFornecedor: {},
      tableContratos: {
        filter: '',
        perPage: 10,
        totalRows: 1,
        currentPage: 1,
        fields: [
          'id',
          'objeto',
          'inicio',
          'final',
          'opcoes',
        ],
        items: [],
      },
      contrato: {
        data_inicio: '',
        data_final: '',
        descricao: '',
        objeto: '',
        responsavel: '',
        telefone_responsavel: '',
        email_responsavel: '',
        fornecedor_id: '',
        status: true,
      },
      createUpdateContrato: true,
      edicao: false,
    }
  },
  created() {
    this.getContratos()
    this.getFornecedorInfo()
  },
  methods: {
    getFornecedorInfo() {
      axios.get(`api/v1/fornecedor/edit/${this.$route.params.id}`, {
        headers: {
          Authorization: 'Bearer '.concat(localStorage.getItem('token')),
          accept: 'application/json',
        },
      })
        .then(res => {
          this.infoFornecedor = res.data.dados.fornecedores
        })
    },

    rowClicked(item) {
      this.idContrato = item.id
      axios.get(`api/v1/contratos/edit/${this.idContrato}`, {
        headers: {
          Authorization: 'Bearer '.concat(localStorage.getItem('token')),
          accept: 'application/json',
        },
      })
        .then(res => {
          const dt = res.data.dados.contratos

          this.contrato.data_inicio = dt.data_inicio
          this.contrato.data_final = dt.data_final
          this.contrato.descricao = dt.descricao
          this.contrato.objeto = dt.objeto
          this.contrato.responsavel = dt.responsavel
          this.contrato.telefone_responsavel = dt.telefone_responsavel
          this.contrato.email_responsavel = dt.email_responsavel
          this.contrato.fornecedor_id = dt.fornecedor_id
          this.contrato.status = dt.status
        })
      this.$refs.modalContrato.show()
      this.createUpdateContrato = false
      this.edicao = true
    },

    async updateContrato() {
      const body = {
        data_inicio: this.contrato.data_inicio,
        data_final: this.contrato.data_final,
        descricao: this.contrato.descricao,
        objeto: this.contrato.objeto,
        responsavel: this.contrato.responsavel,
        telefone_responsavel: this.contrato.telefone_responsavel,
        email_responsavel: this.contrato.email_responsavel,
        fornecedor_id: parseInt(this.$route.params.id),
        status: this.contrato.status,
      }
      await axios.put(`api/v1/contratos/update/${this.idContrato}`, body, {
        headers: {
          Authorization: 'Bearer '.concat(localStorage.getItem('token')),
          accept: 'application/json',
        },
      })
        .then(() => {
          this.$swal({
            title: 'Contrato Atualizado!',
            icon: 'success',
            timer: 1500,
            showConfirmButton: false,
          })
        })
        .catch(() => {
          this.$swal({
            title: 'ERRO',
            icon: 'error',
            text: 'Erro ao atualizar contrato!',
            confirmButton: {
              customClass: 'btn btn-danger',
            },
          })
        })
    },

    async deleteContrato() {
      this.$swal({
        title: 'Tem certeza?',
        text: 'Deseja deletar o contrato?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sim, quero deletar!',
        cancelButtonText: 'Cancelar',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(async result => {
        if (result.value) {
          await axios.delete(`api/v1/contratos/delete/${this.idContrato}`, {
            headers: {
              Authorization: 'Bearer '.concat(localStorage.getItem('token')),
              accept: 'application/json',
            },
          }).then(() => {
            this.$refs.modalContrato.hide()
            this.getContratos()
            this.$swal({
              title: 'Contrato Excluido',
              icon: 'success',
              timer: 1500,
              showConfirmButton: false,
            })
          })
            .catch(() => {
              this.$refs.modalContrato.hide()
              this.getContratos()
              this.$swall({
                title: 'ERRO',
                icon: 'error',
                text: 'Erro ao tentar excluit contrato',
                customClass: {
                  confirmButton: 'btn btn-danger',
                },
              })
            })
        } else {
          this.$refs.modalContrato.hide()
        }
      })
    },

    clearContrato() {
      this.contrato = {
        data_inicio: '',
        data_final: '',
        descricao: '',
        objeto: '',
        responsavel: '',
        telefone_responsavel: '',
        email_responsavel: '',
        fornecedor_id: '',
        status: true,
      }
    },

    storeContrato() {
      const body = {
        data_inicio: this.contrato.data_inicio,
        data_final: this.contrato.data_final,
        descricao: this.contrato.descricao,
        objeto: this.contrato.objeto,
        responsavel: this.contrato.responsavel,
        telefone_responsavel: this.contrato.telefone_responsavel,
        email_responsavel: this.contrato.email_responsavel,
        fornecedor_id: parseInt(this.$route.params.id),
        status: this.contrato.status,
      }
      axios.post('api/v1/contratos/store', body, {
        headers: {
          Authorization: 'Bearer '.concat(localStorage.getItem('token')),
          accept: 'application/json',
        },
      })
        .then(() => {
          this.getContratos()
          this.$swal({
            title: 'Novo contrato cadastrado!',
            icon: 'success',
            timer: 1500,
            showConfirmButton: false,
          })
        })
        .catch(error => {
          this.getContratos()
          this.$swal({
            title: 'ERRO',
            icon: 'error',
            text: error,
            customClass: {
              confirmButton: 'btn btn-danger',
            },
          })
        })
    },

    async getContratos() {
      this.tableContratos.items = []
      await axios.get(`api/v1/contratos/contrato_fornecedor/${this.$route.params.id}`, {
        headers: {
          Authorization: 'Bearer '.concat(localStorage.getItem('token')),
          accept: 'applicatio/json',
        },
      }).then(res => {
        res.data.dados.contratos.map(dt => {
          this.tableContratos.items.unshift({
            id: dt.id,
            fornecedor: dt.fornecedor_id,
            objeto: dt.objeto,
            inicio: this.formatTimezone(dt.data_inicio),
            final: this.formatTimezone(dt.data_final),
            descricao: dt.decricao,
            responsavel: dt.responsavel,
            telefone_responsavel: dt.telefone_responsavel,
            email_responsavel: dt.email_responsavel,
            status: dt.status,
          })
          this.tableContratos.totalRows = this.tableContratos.items.length
        })
      })
    },
  },
}
</script>
